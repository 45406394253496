<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  inject: ['product'],
  data: () => ({
    showLeftArrow: false,
    showRightArrow: true,
    canScroll: false,
  }),
  computed: {
    title() {
      return this.block.fields.Title;
    },
    marginTop() {
      return this.block.fields.MarginTop || 0;
    },
    marginBottom() {
      return this.block.fields.MarginBottom || 0;
    },
    productFunctions() {
      return this.product.productFunctions;
    },
    hasFunctions() {
      return this.productFunctions.length > 0;
    },
  },
};
</script>

<template>
  <div v-if="hasFunctions" class="_layout-maxWidth" :style="{ marginTop: marginTop, marginBottom: marginBottom }">
    <div class="product-functions">
      <div v-if="title" class="product-functions_title">{{ title }}</div>
      <ul class="product-functions_list" :class="{ 'product-functions_list-standalone': !title }">
        <li class="product-functions_list-item" v-for="pfunction in productFunctions" :key="pfunction.title">
          <div class="product-functions_function-title">{{ pfunction.title }}</div>
          <div class="product-functions_function-description">{{ pfunction.description }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
.product-functions {
  display: flex;
  flex-direction: column;
}

.product-functions_title {
  font-family: var(--font-header);
  font-size: 27px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.product-functions_list {
  list-style: none;
  columns: 2;
  margin: 7px -1rem 0px;
  width: 100%;
}

.product-functions_list-item {
  position: relative;
  break-inside: avoid-column;
  margin-bottom: 20px;
}

.product-functions_list-item:before {
  position: absolute;
  content: "•";
  left: -25px;
  line-height: 15px;
  font-size: 16px;
}

.product-functions_list-standalone {
  margin: 7px 0px 0px;
}

.product-functions_function-title {
  font-family: var(--font-header);
  font-size: 15px;
  line-height: 1.2em;
}

.product-functions_function-description {
  font-family: var(--font-body);
  font-size: 15px;
  line-height: 1.2em;
}

@media (--phoneAndTablet) {
  .product-functions_title {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 8px;
  }

  .product-functions_list {
    columns: 1;
    margin-top: 18px;
    margin: 7px 0px 0px;
  }
}

@media (--tablet) {
  .product-functions_title {
    margin: 0 1rem;
  }
}

@media (--phone) {
  .product-functions_list {
    padding-left: 20px;
  }
  .product-functions_list-item:before {
    left: -20px;
  }
}

</style>
